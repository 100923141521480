<template>
  <v-container
    v-if="pageLoading"
    fluid
    class="d-flex justify-center align-center accounts-container"
  >
    <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
  </v-container>

  <v-container class="pa-0 ma-0 pr-10" v-else>
    <v-col cols="6" md="12" sm="12" lg="6">
      <v-row>
        <div class="body-1 font-weight-light mb-6">
          Here are your BeeZBuro modules designed specifically for you to
          ENGAGE, EMPOWER & ELEVATE your workforce and accelerate your
          enterprise growth.
        </div>
      </v-row>
      <v-row>
        <template v-for="(item, index) in modules">
          <div :key="index" class="mb-4" @click="accountModuleSelected(item)">
            <module-card
              class="ma-0 pa-0"
              :title="item.title"
              :description="item.description"
              :icon="item.icon"
              :active="item.isActive"
              :route="item.link"
              :learn="item.learn"
            />
          </div>
        </template>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import BzSubmitButton from "@shared/components/BzSubmitButton";

import ModuleCard from "../components/ModuleCard";
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
export default {
  name: "AccountModules",
  components: {
    ModuleCard,
    BzSubmitButton
  },
  computed: {
    ...mapFields("auth", ["currentUser"]),
    ...mapFields("admin", {
      accountModules: "accountModules"
    })
  },
  data() {
    return {
      activateDialog: false,
      accountId: null,
      pageLoading: false,
      modules: [
        {
          title: "Employee On boarding Module",
          icon: "mdi-card-account-mail",
          description:
            "Automates the on-boarding process by using an end to end workflow to speed up the users in integration",
          isActive: false,
          link: "onboarding-module",
          learn:
            "Is one of the BeeZBuro modules which automates the on-boarding process of users to an organizational entity by using an end to end workflow to speed up the integration and orientation process of a new team member into the Organization."
        },
        {
          title: "Elevation Module",
          icon: "mdi-elevation-rise",
          description:
            "Organizational specific social media communication tool that fosters engagement between all the stakeholders",
          isActive: false,
          link: "elevation-module",
          learn:
            "Is one of the BeeZBuro modules which is an organizational specific social media communication tool that fosters engagement between all the stakeholders inside and out side the organization."
        }
        // {
        //   title: "Communication Module",
        //   icon: "mdi-forum",
        //   description:
        //     "Organizational specific social media communication tool that fosters engagement between all the stakeholders",
        //   isActive: false,
        //   link: "communication-module",
        //   learn:
        //     "Is one of the BeeZBuro modules which is an organizational specific social media communication tool that fosters engagement between all the stakeholders inside and out side the organization."
        // }
      ]
    };
  },
  methods: {
    async accountModuleSelected(accountModule) {
      if (accountModule.link === "elevation-module") {
        if (this.modules[0].isActive == true) {
          this.$router.push({
            name: accountModule.link
          });
        }
      } else {
        this.$router.push({
          name: accountModule.link
        });
      }
      // else {
      //   if (this.accountModules && !this.modules[1].isActive) {
      //     this.activateDialog = true;
      //   } else {
      //     this.$router.push({
      //       name: accountModule.linsk
      //     });
      //   }
      // }
    },

    async activateCommunicationModule() {
      const data = await this.createCommunicationModule(
        this.currentUser.accounts[0]
      );
      console.log(data, "is created");
      // let themode = await this.getAccountModules(this.currentUser.accounts[0]);

      this.activateDialog = false;
      this.modules[1].isActive = true;
    },
    ...mapActions("admin", {
      getAccountModules: "getAccountModules"
    }),
    ...mapActions("modules", {
      createCommunicationModule: "createCommunicationModule"
    })
  },
  async created() {
    this.pageLoading = true;

    let allModules = await this.getAccountModules(this.currentUser.accounts[0]);
    // for (var index in allModules) {
    //   switch (allModules[index].moduleName) {
    //     case "ONBOARDING": {
    //       this.modules[0].isActive = true;
    //       break;
    //     }
    //     case "ELEVATION": {
    //       this.modules[1].isActive = true;
    //       break;
    //     }
    //   }
    // }
    for (var index in allModules) {
      switch (allModules[index].moduleName) {
        case "Employee Onboarding Module": {
          if (allModules[index].status == "ACTIVE") {
            this.modules[0].isActive = true;
          }
          break;
        }
        case "Elevation Module": {
          if (allModules[index].status == "ACTIVE") {
            this.modules[1].isActive = true;
          }
          break;
        }
      }
    }
    // allModules[0]
    //   ? (this.modules[0].isActive = true)
    //   : (this.modules[0].isActive = false);

    // allModules[1]
    //   ? (this.modules[2].isActive = true)
    //   : (this.modules[2].isActive = false);
    this.pageLoading = false;
  }
};
</script>

<style scoped>
.accounts-container {
  height: 60vh;
}
</style>
