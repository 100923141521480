<template>
  <v-card hover class="pa-0 ma-0">
    <v-container fluid fill-height class="full-width ml-6 pa-0 ma-0">
      <v-row align="center">
        <v-col cols="2" class="ml-7">
          <v-icon v-if="active" size="55" color="primaryLight" class="mb-6">{{
            icon
          }}</v-icon>
          <v-icon v-else size="55" color="textGrey" class="mb-6 ">{{
            icon
          }}</v-icon>
        </v-col>
        <v-col cols="9" class="">
          <div class="title font-weight-regular mb-2">{{ title }}</div>
          <div class="font-weight-light subtitle-2">
            {{ description }}
          </div>

          <div v-if="active">
            <v-dialog
              v-model="dialog"
              width="40%"
              class="d-flex justify-center text-center align-center"
            >
              <template v-slot:activator="{ on }">
                <v-btn link text class="pa-0" v-on="on" color="primaryLight"
                  >Learn More</v-btn
                >
              </template>
              <v-card>
                <v-card-title
                  class=" d-flex justify-center primary textLight--text title font-weight-thin  "
                >
                  {{ title }}
                </v-card-title>
                <v-divider></v-divider>

                <v-card-text class="d-flex justify-center pa-12">
                  {{ learn }}
                </v-card-text>
                <v-card-actions class="d-flex justify-center">
                  <bz-submit-button @click="closeDialog()" title="Got it" />
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>

          <div v-else>
            <v-btn link text class="pa-0" color="secondaryText"
              >Learn More</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { eventBus } from "../../main";

import BzSubmitButton from "@shared/components/BzSubmitButton";
export default {
  name: "ModuleCard",
  data: () => ({
    dialog: false
  }),

  props: ["title", "description", "icon", "active", "route", "learn"],
  components: {
    BzSubmitButton
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    }
  },

  created() {
    eventBus.$on(this.submitTo, () => {
      this.closeDialog();
    });
  }
};
</script>

<style scoped>
.module-card {
  box-shadow: 0px 0px 82px #00000030;
  border-radius: 3px;
}
.sub-text {
  width: 289px;
  line-height: 0.7;
}
</style>
